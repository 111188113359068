

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {


        $(".navbar-toggle").on('click', function (e) {
          $(".menu-dropdown-wrapper.active").removeClass('active');
          $(".navbar-nav").removeClass('active');
          $(".menu-item.active").removeClass('active');
        });

        $('.menu-back').on('click', function (e) {
          e.preventDefault();
          $(".menu-dropdown-wrapper.active").removeClass('active');
          $(".menu-item.active").removeClass('active');
        });

        $(".tooltip-right, .tooltip-top").on('click', function (e) {
          e.preventDefault();
        });

        $(".tooltip-right").tooltip({
          placement: "right",
          title: "Integer at faucibus urna. Nullam condimentum leo id elit sagittis auctor. Curabitur elementum nunc a leo imperdiet"
        });

        $(".tooltip-top").tooltip({
          placement: "top",
          title: "Carte grise, frais d'immatriculation, carburant, mise à la route en supplément."
        });

        // init Isotope
        setTimeout(function () {

          if ($('.listing-wrapper .card-item').length) {
            var $grid = $('.listing-wrapper').isotope({
              layoutMode: 'fitRows',
              itemSelector: '.card-item',
              fitRows: {
                gutter: 28
              }
            });

            $('.filters select').on('change', function () {
              var filterValue = '';
              $('.filters select').each(function () {
                filterValue += this.value;
              });
              $grid.isotope({ filter: filterValue });
            });
          }
        }, 300);

        // var fancybox = $("a.fancybox");
        // if (fancybox) {
        //   $(fancybox).fancybox();
        // }

        var fancybox = $("a.fancybox");
        if (fancybox) {
          $(fancybox).fancybox({
            touch: false,
            loop: true,
            thumbs: {
              autoStart: true
            },
          });
        }

        // resizeBlocProduct();
        // $(window).on('resize', function() {
        //   resizeBlocProduct();
        // });

        //  function resizeBlocProduct() {
        //   $('.bloc-photo-large .circle').css('height', $('.bloc-photo-large .circle').width());
        // }

        $('.match-height').matchHeight();
        $('.match-height-2').matchHeight();
        $('.match-height-3').matchHeight();

        // JavaScript to be fired on all pages
        var headerHeight = $('.section-cover-picture').innerHeight();
        var initialLogo = $('.main-logo.active');
        var inactiveLogo = $('.main-logo:not(.active)');

        //   $(window).scroll(function () {
        //    if ($(this).scrollTop() > headerHeight) {
        //     if (!initialLogo.hasClass('logo-black')) {
        //       initialLogo.removeClass('active');
        //       inactiveLogo.addClass('active');
        //     }
        //   }
        //   else if ($(this).scrollTop() < headerHeight) {
        //     initialLogo.addClass('active');
        //     inactiveLogo.removeClass('active');
        //   }
        // });

        $(window).resize(function () {
          headerHeight = $('.section-cover-picture').innerHeight();
        });

        // $(window).scroll(function () {
        //   var scrollT = $(this).scrollTop();
        //   $('.bloc-papillon').css("transform", "translateY(" + scrollT/6 + "px");
        // });

        /* parallax img bg */
        $('.parallax-window').parallax();




        /* ************************************************************* */
        /* script pour les onglets dans les fiches produits */

        $('[data-onglet]').on('click', function (e) {
          e.preventDefault();
          var target = '#' + $(this).data('onglet');

          if (!$(this).hasClass('bold')) {
            var activeOnglets = $('.onglets-tabs > .bold');
            Object.values(activeOnglets).forEach(function (item) {
              $(item).removeClass('bold');
            });

            $(this).addClass('bold');
          }

          if (!$(target).hasClass('visible')) {
            var activeItems = $('.content-onglet > .visible');
            Object.values(activeItems).forEach(function (item) {
              $(item).removeClass('visible');
            });

            $(target).addClass('visible');
          }
        });



        $(".bulle-sticky").on('click', function (e) {
          $.fancybox.open($('.bulle-content'));
          e.preventDefault();
          e.stopPropagation();
        });

        $("[toggle-fancybox='form']").on('click', function (e) {
          $.fancybox.open($('.hidden-form'));
          e.preventDefault();
          e.stopPropagation();
        });

        // Partenaires slider
        $('.slick').slick({
          dots: true,
          arrows: false,
          autoplay: true,
          speed: 500,
          infinite: true,
          centerMode: false,
          slidesToShow: 4,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 992,
              settings: {
                dots: false,
                slidesToShow: 2,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 768,
              settings: {
                dots: false,
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }]
        });

        // Partenaires slider
        $('.slick-subservice').slick({
          dots: true,
          arrows: false,
          autoplay: true,
          centerMode: true,
          variableWidth: true,
          slidesToShow: 1
        });

        $('.woocommerce-tabs .content-onglet > div:first-child').addClass('visible');
        $('.woocommerce-tabs .onglets-tabs > div:first-child').addClass('bold');

        // Smooth Scroll Anchor
        $('a[href*="#"]')
          .not('[href="#"]')
          .not('[href="#0"]')
          .click(function (event) {
            if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
              var target = $(this.hash);
              target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

              if (target.length) {

                event.preventDefault();
                $('html, body').animate({
                  scrollTop: target.offset().top
                }, 1000);
              }
            }
          });


        $('.product-main-slider').slick({
          autoplay: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          fade: true,
          asNavFor: '.product-list-slider',
        });

        $('.product-list-slider').slick({
          autoplay: true,
          slidesToShow: 5,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
          focusOnSelect: true,
          asNavFor: '.product-main-slider',
          responsive: [
            {
              breakpoint: 1500,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 400,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              }
            }]
        });





        const menu = document.querySelector('header.banner');


        menu.addEventListener('click', function (e) {
          const target = e.target;
          if (target.classList.contains('primary-menu-btn')) {
            const activeLi = menu.querySelector('.primary-menu-btn.active');
            if (target.classList.contains('active')) {
              target.classList.remove('active');
            } else {
              activeLi && activeLi.classList.remove('active');
              target.classList.add('active');
            }
          }
          if (target.classList.contains('menu-back') && target.closest('.primary-menu-btn').classList.contains('active')) {
            target.closest('.primary-menu-btn').classList.remove('active');
          }
        })

        const hamburger = document.querySelector('header.banner .hamburger-menu-responsive');

        hamburger.addEventListener('click', function (e) {
          const target = e.target;
          const header = target.closest('header.banner');
          if (header.classList.contains('menu-mobile-open')) {
            header.classList.remove('menu-mobile-open');
            document.body.style.overflow = "auto";
            const activeLi = header.querySelector('.primary-menu-btn.active');
            activeLi && activeLi.classList.remove('active');
          }
          else {
            header.classList.add('menu-mobile-open');
            document.body.style.overflow = "hidden";
          }
        })



        // var menuHeader = document.getElementById('menu-header');
        // var menuDropdownLink = document.querySelectorAll('#menu-header .menu-item-has-children');
        // var logoBlack = document.querySelector('.main-logo.logo-black');
        // var logoWhite = document.querySelector('.main-logo.logo-white');
        // var banner = document.querySelector('.banner');

        // Object.values(menuDropdownLink).forEach(function(item, key) {
        //   item.addEventListener('click', function(e) {
        //   e.preventDefault();
        //   var dropdown = document.querySelector('.menu-dropdown-wrapper[data-menu="' + key +'"]');

        //   if (banner.classList.contains('transparent') && !menuHeader.hasClass('active')) {
        //     logoBlack.classList.add('active');
        //     logoWhite.classList.remove('active');
        //   } else if (banner.classList.contains('transparent') && menuHeader.hasClass('active')) {
        //     logoBlack.classList.remove('active');
        //     logoWhite.classList.add('active');
        //   }

        //   if (!this.classList.contains('active')) {
        //     menuHeader.classList.add('active');

        //     if ($('.menu-dropdown-wrapper.active')) {
        //       $('.menu-dropdown-wrapper.active').removeClass('active');
        //     }

        //     if ($('.menu-product.active')) {
        //       $('.menu-product.active').removeClass('active');
        //     }

        //     dropdown.classList.add('active');
        //     this.classList.add('active');
        //   } else {
        //     this.classList.remove('active');
        //     menuHeader.classList.remove('active');
        //     dropdown.classList.remove('active');
        //   }
        //   });
        // });


        // var submenus = document.querySelectorAll('.menu-dropdown-wrapper');
        // var submenuLinks = document.querySelectorAll('.menu-product.menu-item');


        // window.onscroll = function () {
        //   closeMainMenu();
        // }

        // function closeMainMenu() {
        //   if (window.matchMedia("(min-width: 1280px)").matches) {
        //     var menuHeader = $('#menu-header');

        //     if (menuHeader.hasClass('active')) {
        //       menuHeader.removeClass('active');
        //     }

        //     submenus.forEach(function (submenu) {
        //       if (submenu.classList.contains('active')) {
        //         submenu.classList.remove('active');
        //       }
        //     });

        //     submenuLinks.forEach(function (submenuLink) {
        //       if (submenuLink.classList.contains('active')) {
        //         submenuLink.classList.remove('active');
        //       }
        //     });
        //   }
        // }

        // function closeMenuOnClickOut() {
        //   document.addEventListener('click', function (event) {
        //     var clickOnMenu = false;

        //     event.path.forEach(function (item) {
        //       if (item.classList != undefined) {
        //         if (item.classList.contains('menu-dropdown-wrapper') || item.classList.contains('menu-item')) {
        //           clickOnMenu = true;
        //         }
        //       }
        //     });

        //     if (!clickOnMenu && menuIsOpen()) {
        //       var menuHeader = $('#menu-primary-bottom');
        //       var logoBlack = $('.main-logo.logo-black');
        //       var logoWhite = $('.main-logo.logo-white');

        //       if ($('.banner').hasClass('transparent') && !menuHeader.hasClass('active')) {
        //         logoBlack.addClass('active');
        //         logoWhite.removeClass('active');
        //       } else if ($('.banner').hasClass('transparent') && menuHeader.hasClass('active')) {
        //         logoBlack.removeClass('active');
        //         logoWhite.addClass('active');
        //       }
        //       closeMainMenu();
        //     }
        //   });
        // }

        // closeMenuOnClickOut();

        // function menuIsOpen() {
        //   var submenu = document.querySelector('header .menu-dropdown-wrapper.active');

        //   if (submenu) {
        //     return true;
        //   }

        //   return false;
        // }


        // var menuDropdown0 = $('.menu-dropdown-wrapper[data-menu="menu-0"]');
        // var menuDropdown1 = $('.menu-dropdown-wrapper[data-menu="menu-1"]');
        // var menuDropdown2 = $('.menu-dropdown-wrapper[data-menu="menu-2"]');
        // var menuHeader = $('#menu-primary-bottom');
        // var logoBlack = $('.main-logo.logo-black');
        // var logoWhite = $('.main-logo.logo-white');

        // $('#menu-primary-bottom > li > a').on('click', function (e) {
        //   console.log('click');
        //   e.preventDefault();
        //   if ($('.banner').hasClass('transparent') && !menuHeader.hasClass('active')) {
        //     logoBlack.addClass('active');
        //     logoWhite.removeClass('active');
        //   } else if ($('.banner').hasClass('transparent') && menuHeader.hasClass('active')) {
        //     logoBlack.removeClass('active');
        //     logoWhite.addClass('active');
        //   }

        //   if ($(this).parent().hasClass('menu-0')) menuDropdown = menuDropdown0;
        //   if ($(this).parent().hasClass('menu-1')) menuDropdown = menuDropdown1;
        //   if ($(this).parent().hasClass('menu-2')) menuDropdown = menuDropdown2;
        //   if (!$(this).parent().hasClass('active')) {
        //     menuHeader.addClass('active');
        //     if ($('.menu-dropdown-wrapper.active')) {
        //       $('.menu-dropdown-wrapper.active').removeClass('active');
        //     }
        //     if ($('.menu-product.active')) {
        //       $('.menu-product.active').removeClass('active');
        //     }
        //     menuDropdown.addClass('active');
        //     $(this).parent().addClass('active');
        //   } else {
        //     $(this).parent().removeClass('active');
        //     menuHeader.removeClass('active');
        //     menuDropdown.removeClass('active');
        //   }
        // });

      },
      finalize: function () {
      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
